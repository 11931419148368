import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LayoutBox } from "@/layout/box/Box";
import { Heading } from "@petsmart-ui/sparky";
import { DdcPackagesRadioList } from "./DdcPackagesRadioList";
import { LayoutStack } from "@/layout/stack/Stack";
import { SearchableList } from "@prism/psm-ui-components";

// Actions
import { getDdcPackages, setSelectedDdcPackages } from "./ddcPackagesActions";

// Selectors
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { getStoreRegion } from "@/core/selectors/entitiesSelector";
import { selectDdcPackages, selectSelectedDdcPackages } from "./ddcPackagesSelectors";

// Utils
import isEmpty from "lodash/isEmpty";
import fuzzyFilter from "@/core/utils/arrayUtils/fuzzyFilter";

// Local helpers
export const filterDdcPackages = ({ searchValue, ddcPackages }) => {
  if (!searchValue) {
    return ddcPackages;
  }

  const fuzzySearchPackages = ddcPackages?.map(packages => {
    const groupPackages = packages?.groupPackages.map(groupPackage => {
      return {
        ...groupPackage,
        packages: fuzzyFilter(groupPackage?.packages, "displayName", searchValue),
      };
    });

    const individualPackages = packages?.individualPackages.map(individualPackage => {
      return {
        ...individualPackage,
        packages: fuzzyFilter(individualPackage?.packages, "displayName", searchValue),
      };
    });

    return {
      groupPackages,
      individualPackages,
    };
  });

  return fuzzySearchPackages;
};

/**
 * React view component for middle column on DDC packages page
 * @memberOf Views.Purchase
 * @param {*} props
 * @returns {JSX.Element}
 */
export const DDCPackagesListColumnComponent = props => {
  const {
    componentId,
    isHidden,
    fetchDdcPackages,
    ddcPackages,
    storeRegion,
    selectDdcPackages,
  } = props;

  const [searchValue, setSearchValue] = useState("");
  const [filteredPackages, setFilteredPackages] = useState(ddcPackages);

  useEffect(() => {
    setFilteredPackages(ddcPackages);
  }, [ddcPackages]);

  useEffect(() => {
    // To avoid race condition - fetch ddc packages api call is dependent on hours api call
    // fetch ddc packages needs region as dependency
    if (storeRegion) fetchDdcPackages();
  }, [storeRegion]);

  // Handlers
  const handleOnChange = event => {
    const searchValue = event.target.value;
    setSearchValue(searchValue);

    const fuzzySearchPackages = filterDdcPackages({ searchValue, ddcPackages });
    setFilteredPackages(fuzzySearchPackages);
  };

  if (isHidden || !filteredPackages.length) return null;

  return (
    <LayoutBox id={componentId} padding="scale-G1">
      <LayoutStack>
        <LayoutBox padding="scale-0">
          <Heading tagName="h2" size="title">
            Purchase DDC Play & Save
          </Heading>
        </LayoutBox>

        <LayoutBox padding="scale-0">
          <SearchableList
            id={componentId}
            placeholder="Search"
            value={searchValue}
            onChange={handleOnChange}
            variant="full"
          >
            <LayoutStack space="scale-G2" style={{ marginTop: "10px" }}>
              {/* Group Passes */}
              <LayoutStack>
                <Heading tagName="h2" size="title">
                  Group Passes
                </Heading>
                <DdcPackagesRadioList
                  packages={filteredPackages[0]?.groupPackages}
                  componentId="DdcPackagesRadioListGroup"
                  onChange={selectedPackage => selectDdcPackages(selectedPackage, "groupPackages")}
                />
              </LayoutStack>

              {/* Individual Passes */}
              <LayoutStack>
                <Heading tagName="h2" size="title">
                  Individual Passes
                </Heading>
                <DdcPackagesRadioList
                  packages={filteredPackages[0]?.individualPackages}
                  componentId="DdcPackagesRadioListIndividual"
                  onChange={selectedPackage =>
                    selectDdcPackages(selectedPackage, "individualPackages")
                  }
                />
              </LayoutStack>
            </LayoutStack>
          </SearchableList>
        </LayoutBox>
      </LayoutStack>
    </LayoutBox>
  );
};

/**
 * Redux Connect function for middle column on DDC packages page
 * @memberOf Views.Purchase
 * @summary path: /purchase/:customerKey/ddc-packages
 * @function
 * @name DDCPackagesListColumn
 * @example <DDCPackagesListColumn />
 */
export const DDCPackagesListColumn = connect(
  state => {
    const ddcPackages = selectDdcPackages(state);
    const selectedDdcPackages = selectSelectedDdcPackages(state);
    const storeNumber = getStoreNumber(state);
    const storeRegion = getStoreRegion(state, { storeNumber });

    return {
      componentId: "DDCPackagesListColumn",
      isHidden: isEmpty(ddcPackages),
      ddcPackages,
      selectedDdcPackages,
      storeRegion,
    };
  },
  dispatch => {
    return {
      fetchDdcPackages: () => {
        dispatch(getDdcPackages());
      },
      setSelectedDdcPackages: selectedPackages => {
        dispatch(setSelectedDdcPackages(selectedPackages));
      },
    };
  },
  (propsFromState, propsFromDispatch) => {
    const { componentId, isHidden, ddcPackages, selectedDdcPackages, storeRegion } = propsFromState;

    const { fetchDdcPackages, setSelectedDdcPackages } = propsFromDispatch;

    return {
      componentId,
      isHidden,
      ddcPackages,
      storeRegion,

      // actions
      fetchDdcPackages,
      selectDdcPackages: (selectedPackage, packageCategory) => {
        const ddcPackage = {
          [packageCategory]: selectedPackage,
        };

        return setSelectedDdcPackages({ ...selectedDdcPackages, ...ddcPackage });
      },
    };
  },
)(DDCPackagesListColumnComponent);
