/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const GET_DDC_PACKAGES = "GET_DDC_PACKAGES";
export const GET_DDC_PACKAGES_REQUEST = "GET_DDC_PACKAGES_REQUEST";
export const GET_DDC_PACKAGES_SUCCESS = "GET_DDC_PACKAGES_SUCCESS";
export const GET_DDC_PACKAGES_FAILURE = "GET_DDC_PACKAGES_FAILURE";

export const SET_SELECTED_DDC_PACKAGES = "SET_SELECTED_DDC_PACKAGES";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

/**
 * Action to fetch ddc purchase packages
 * @memberOf Actions.ddcPackages
 * @function
 * @name getDdcPackages
 * @returns {object}
 * @example getDdcPackages()
 */
export const getDdcPackages = () => ({
  type: GET_DDC_PACKAGES,
});

/**
 * Action to request ddc purchase packages
 * @memberOf Actions.ddcPackages
 * @function
 * @name getDdcPackagesRequest
 * @returns {object}
 * @example getDdcPackagesRequest()
 */
export const getDdcPackagesRequest = () => ({
  type: GET_DDC_PACKAGES_REQUEST,
});

/**
 * Action to set ddc purchase packages
 * @memberOf Actions.ddcPackages
 * @function
 * @name getDdcPackagesSuccess
 * @returns {object}
 * @example getDdcPackagesSuccess()
 */
export const getDdcPackagesSuccess = ddcPackages => ({
  type: GET_DDC_PACKAGES_SUCCESS,
  ddcPackages,
});

/**
 * Action to set error for ddc purchase packages
 * @memberOf Actions.ddcPackages
 * @function
 * @name getDdcPackagesFailure
 * @returns {object}
 * @example getDdcPackagesFailure()
 */
export const getDdcPackagesFailure = error => ({
  type: GET_DDC_PACKAGES_FAILURE,
  error,
});

/**
 * Action to set ddc package selection
 * @memberOf Actions.ddcPackages
 * @function
 * @param {object} selectedPackages
 * @name setSelectedDdcPackages
 * @returns {object}
 * @example setSelectedDdcPackages(selectedPackages)
 */
export const setSelectedDdcPackages = selectedPackages => ({
  type: SET_SELECTED_DDC_PACKAGES,
  selectedPackages,
});
