import { createSelector } from "reselect";
import { getProps, getState } from "core/selectors/commonSelector";
import moment from "moment";
import { get, uniq } from "lodash/fp";
import {
  formatCalendarDateMoment,
  formatDayNameMonthDay,
} from "@/core/utils/dateUtils/formatDateTime";
import { FEEDING_CHARGE } from "./hotelEngagementConstants";
import momentTz from "moment-timezone";
import { getPets, getStoreTimeZone } from "@/core/selectors/entitiesSelector";
import {
  getAllPetIdAndEngagementTypesFromEngagementId,
  getLatestEndDatetime,
  allEngagementPetServiceIdsMatch,
} from "core/utils/engagementUtils/engagementUtils";
import groupBy from "lodash/groupBy";
import { APPOINTMENT_STATUS } from "core/constants";
import {
  selectHotelAddonsListAddonsByPet,
  selectIncludedDescriptorsNotes,
} from "../hotelAddons/hotelAddonsLIstSelectors";
import isEmpty from "lodash/isEmpty";
import {
  dedupeAddonsFromEngagements,
  getAddonsFromPetEngagements,
} from "@/core/utils/hotelEngagementUtils/getAddonsFromPetEngagements";
import { sortHotelEngagements } from "@/core/utils/hotelEngagementUtils/sortHotelEngagements";
import { getCurrentPet } from "core/selectors/persistentSelectors";
import {
  getPendingAppointmentServicesByPet,
  selectHotelPetServiceById,
} from "@/web/pendingAppointment/selectors/pendingAppointmentSelectors";
import { selectHotelBookingPetList } from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { uniqBy } from "lodash";
import normalizeArrayByProperty from "@/core/utils/normalizeUtils/normalizeArray";
import { checkIsReservationDone } from "../itineraryHistoryList/itineraryHistoryListSelectors";
import { getAllIncludedProductNumbers } from "@/core/utils/hotelEngagementUtils/getAllIncludedProductNumbers";

export const getHotelEngagements = createSelector([getState], state => {
  return state?.hotelEngagements?.engagements;
});

/**
 *  Selector to get a specific hotel engagement by id
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name getHotelEngagementByEngagementId
 *  @param {Object} state - redux state
 *  @param {Object} props
 *  @param {string} props.engagementId - the engagement id
 *  @returns {Object} engagement
 *  @example
 *
 *  getHotelEngagementByEngagementId(state, { engagementId })
 */
export const getHotelEngagementByEngagementId = createSelector(
  [getHotelEngagements, (state, props) => props],
  (engagements = {}, { engagementId }) => {
    return engagements[engagementId];
  },
);

/**
 *  Selector to get all hotel engagements as an array
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name getHotelEngagementsArray
 *  @param {Object} state - redux state
 *  @returns {Array} Array of engagements
 *  @example
 *
 *  getHotelEngagementsArray(state)
 */
export const getHotelEngagementsArray = createSelector([getHotelEngagements], (engagements = {}) =>
  sortHotelEngagements(Object.values(engagements) ?? []),
);

/**
 * Selector to get hotel engagements as an array for given pets, defaults to all pets if petIds aren't provided
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectHotelEngagementsByPetIds
 * @param {Object} state - redux state
 * @param {Object} props
 * @param {Number[]} props.petIds - optional
 * @returns {Array} Array of engagements
 */
export const selectHotelEngagementsByPetIds = createSelector(
  [getHotelEngagementsArray, getProps],
  (engagements = [], { petIds } = {}) =>
    petIds ? engagements?.filter(({ petId }) => petIds?.includes(petId)) : engagements,
);

/**
 *  Selector to get a list of all pets on the current hotel itinerary
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name getHotelEngagementsPets
 *  @param {Object} state - redux state
 *  @returns {Array} array of unique engagement pet ids
 *  @example
 *
 *  getHotelEngagementsPets(state)
 */
export const getHotelEngagementsPets = createSelector([getHotelEngagementsArray], engagements => {
  return engagements && uniq(engagements.map(engagement => engagement?.petId))?.sort();
});

/**
 *  Selector to return the starting pet id for the check in/out page
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name getInitialHotelEngagementsPet
 *  @param {Object} state - redux state
 *  @returns {string} petId
 *  @example
 *
 *  getInitialHotelEngagementsPet(state)
 */
export const getInitialHotelEngagementsPet = createSelector(
  [getHotelEngagementsPets, getCurrentPet],
  (pets, currentPet) => {
    if (!pets || pets.length === 0) return currentPet;
    const isSelectedPetInEngagements = pets.find(petId => petId === currentPet);

    return isSelectedPetInEngagements ? currentPet : get([0], pets);
  },
);

/**
 *  Selector to get the first hotel engagement for the current itinerary
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name getFirstHotelEngagement
 *  @param {Object} state - redux state
 *  @returns {Object} engagement
 *  @example
 *
 *  getFirstHotelEngagement(state)
 */
export const getFirstHotelEngagement = createSelector([getHotelEngagementsArray], engagements => {
  return get([0], engagements);
});

/**
 *  Selector to get all hotel engagements for a given pet sorted in ascending order by start date
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name getPetHotelEngagements
 *  @param {Object} state - redux state
 *  @param {Object} props
 *  @param {string} props.petId
 *  @returns {Array} engagement
 *  @example
 *
 *  getPetHotelEngagements(state, { petId })
 */
export const getPetHotelEngagements = createSelector(
  [getHotelEngagementsArray, (state, props) => props],
  (engagements = [], { petId }) =>
    sortHotelEngagements(engagements.filter(engagement => engagement?.petId === petId)),
);

export const getFirstHotelEngagementByPet = createSelector(
  [getPetHotelEngagements],
  (petEngagements = []) => petEngagements[0] ?? {},
);

export const getFirstHotelEngagementStatusByPet = createSelector(
  [getFirstHotelEngagementByPet],
  (petEngagement = {}) => {
    return petEngagement?.status || "";
  },
);

/**
 *  Selector to get the engagement for the last day of stay for a given pet
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name getLastDayEngagementByPetId
 *  @param {Object} state - redux state
 *  @returns {Object} engagement
 *  @example
 *
 *  getLastDayEngagementByPetId(state, { petId })
 */
export const getLastDayEngagementByPetId = createSelector([getPetHotelEngagements], engagements => {
  return engagements && engagements.reduce((a, b) => (a.endDateTime > b.endDateTime ? a : b), {});
});

export const selectEarliestCheckOut = createSelector([getHotelEngagements], engagements => {
  const state = { hotelEngagements: { engagements } };
  const petIds = getHotelEngagementsPets(state);
  const checkOutDates = petIds
    ?.map(petId => getLastDayEngagementByPetId(state, { petId })?.endDatetime)
    ?.sort((a, b) => moment(a).isBefore(moment(b), "day"));

  return checkOutDates?.at(-1);
});

export const getLastHotelEngagementByPet = createSelector(
  [getPetHotelEngagements],
  (petEngagements = []) => petEngagements?.at(-1) ?? {},
);

// The getHotelItinerary selector isn't being used here because importing it creates a dependency cycle and tests will fail
export const getStoreTimeZoneForHotelItinerary = createSelector([getState], state =>
  getStoreTimeZone(state, { storeNumber: state?.hotelItinerary?.itinerary?.storeNumber }),
);

/**
 *  Selector to get a pet engagement based on the current date, current itinerary & pet
 *  in state.hotelItinerary && state.persistent.currentPet
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name selectCurrentHotelEngagement
 *  @param {Object} state - redux state
 *  @param {Object} props
 *  @param {string} props.petId - param to specify pet
 *  @param {string} props.date - optional param to specify date to filter by (defaults to 'today')
 *  @returns {Object} engagement - the engagement for a given pet based on the current date
 *  @example selectCurrentHotelEngagement(state, { petId })
 */
export const selectCurrentHotelEngagement = createSelector(
  [getPetHotelEngagements, getStoreTimeZoneForHotelItinerary, (state, props) => props],
  (sortedEngagements = [], storeTimeZone, { date } = {}) => {
    const todayInStoreTz = momentTz.tz(date, storeTimeZone);
    const first = sortedEngagements[0] ?? {};
    const last = sortedEngagements.at(-1) ?? {};

    if (todayInStoreTz.isSameOrBefore(moment(first?.startDatetime), "day")) return first;
    if (todayInStoreTz.isSameOrAfter(moment(last?.startDatetime), "day")) return last;

    return sortedEngagements?.find(({ startDatetime }) => {
      return todayInStoreTz.isSame(moment(startDatetime), "day");
    });
  },
);

/**
 *  Selector to get the petService object from a given engagement
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name getEngagementPetServiceById
 *  @param {Object} state - redux state
 *  @param {Object} props
 *  @param {string} props.engagementId
 *  @returns {Object} engagement
 *  @example
 *
 *  getEngagementPetServiceById(state, { engagementId })
 */
export const getEngagementPetServiceById = createSelector(
  [getHotelEngagementByEngagementId],
  engagement => {
    return engagement?.petService;
  },
);

/**
 *  Select all addons on an itinerary for a given pet. It is possible for an addOn to not be
 *  on every engagement, so we can't use the first engagement for a given pet. Instead we
 *  will get every addon on each engagement.
 *  NOTE: the array returned will contain duplicate addons, use selectAppliedAddonsFromEngagementsByPet to get the deduped array
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name selectAllAddonsByPet
 *  @param {Object} state - redux state
 *  @returns {Array} addon obj with extra startDatetime attr to be used to build customFrequencyDates
 *  @example
 *
 *  selectAllAddonsByPet(state, { petId })
 */
export const selectAllAddonsByPet = createSelector(
  [getPetHotelEngagements],
  (petEngagements = []) => getAddonsFromPetEngagements(petEngagements),
);

/**
 *  Selects all addons for a pet on an itinerary and dedupes them with the groupingId
 *  Note: be careful using this when building an itinerary as the duplicate addons are needed and may be removed using this
 *  Note: quantity and finalPrice are not adjusted for duplicates, use the following selectors for total price and total quantity
 *  {@link Selectors.HotelEngagements.selectAddonTotalQtyByGroupingId} & {@link Selectors.HotelEngagements.selectAddonTotalPriceByGroupingId}
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name selectAppliedAddonsFromEngagementsByPet
 *  @param {Object} state - redux state
 *  @returns {Array} deduped array of pet addons
 *  @example selectAppliedAddonsFromEngagementsByPet(state, { petId })
 */
export const selectAppliedAddonsFromEngagementsByPet = createSelector(
  [selectAllAddonsByPet, getStoreTimeZoneForHotelItinerary],
  (addons = [], timeZone) => {
    return dedupeAddonsFromEngagements(addons, date => formatCalendarDateMoment(date, timeZone));
  },
);

/**
 * Selects addons to show in applied addon section of check in & out pages
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectAppliedAddonsForCartColumn
 * @param {Object} state - redux state
 * @returns {Array} array of pet addons
 * @example selectAppliedAddonsForCartColumn(state, { petId })
 */
export const selectAppliedAddonsForCartColumn = createSelector(
  [selectAppliedAddonsFromEngagementsByPet],
  (addons = []) => {
    const includedProductNumbers = getAllIncludedProductNumbers(addons);
    return addons?.filter(({ addOnType, isAutoApplyAddOn, addOnProductNumber }) => {
      const isFeedingCharge = addOnType === FEEDING_CHARGE;
      const isIncludedProduct =
        isAutoApplyAddOn && includedProductNumbers.includes(addOnProductNumber);

      return !isFeedingCharge && !isIncludedProduct;
    });
  },
);

/**
 *  Selects a given addon from the deduped list of all addons for a pet on an itinerary
 *  Note: quantity and finalPrice are not adjusted for duplicates, use the following selectors for total price and total quantity
 *  {@link Selectors.HotelEngagements.selectAddonTotalQtyByGroupingId} & {@link Selectors.HotelEngagements.selectAddonTotalPriceByGroupingId}
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name selectAddonByPetAndAddonId
 *  @param {string} addonId - id of addon to get specials from
 *  @returns {Object} the addon that has addOnProductNumber === addonId
 *  @example
 *
 *  selectAddonByPetAndAddonId(addonId)(state, { petId })
 */
export const selectAddonByPetAndAddonId = addonId =>
  createSelector(
    [selectAppliedAddonsFromEngagementsByPet],
    (addons = []) =>
      addons.find(({ addOnProductNumber } = {}) => addOnProductNumber === addonId) ?? {},
  );

/**
 * Selector to get all feeding charge addons for a given pet
 * Note: adds engagementId and startDatetime to the addon obj
 * to be used for reference when building price override req
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectFeedingChargeAddons
 * @param {Object} state - redux state
 * @param {string} props.petId
 * @returns {array} all feeding charge type addons for a given pet
 * @example selectFeedingChargeAddons(state, { petId })
 */
export const selectFeedingChargeAddons = createSelector(
  [getPetHotelEngagements],
  (engagements = []) =>
    engagements.reduce((feedingCharges, { addOns = [], engagementId, startDatetime } = {}) => {
      const feedingCharge = addOns.find(({ addOnType } = {}) => addOnType === FEEDING_CHARGE);
      return feedingCharge
        ? [...feedingCharges, { ...feedingCharge, engagementId, startDatetime }]
        : feedingCharges;
    }, []),
);

/**
 * Selector to get all addons with a given groupingId for a pet
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectAddonsByGroupingId
 * @param {string} groupingId
 * @param {Object} state - redux state
 * @param {string} props.petId
 * @returns {array} array of addon objects
 * @example selectAddonsByGroupingId(groupingId)(state, { petId })
 */
export const selectAddonsByGroupingId = groupingId =>
  createSelector([getPetHotelEngagements], (engagements = {}) =>
    Object.values(engagements).reduce(
      (addonGroup, { addOns = [], engagementId, startDatetime } = {}) => {
        return [
          ...addonGroup,
          ...addOns
            .filter(addon => addon?.groupingId === groupingId)
            .map(addon => ({ ...addon, engagementId, startDatetime })),
        ];
      },
      [],
    ),
  );

/**
 * Select quantity for a given addon across engagements
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectAddonTotalQtyByGroupingId
 * @param {string} groupingId
 * @param {Object} state - redux state
 * @returns {(state: Object, props: Object) => Number} selector for sum of qty's from addons with given groupingId
 * @example selectAddonTotalQtyByGroupingId(groupingId)(state, { petId });
 */
export const selectAddonTotalQtyByGroupingId = groupingId =>
  createSelector([selectAddonsByGroupingId(groupingId)], (addons = []) =>
    addons?.reduce((qty, { quantity = 0 } = {}) => qty + quantity, 0),
  );

/**
 * Select price for a given addon across engagements
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectAddonTotalPriceByGroupingId
 * @param {string} groupingId
 * @param {Object} state - redux state
 * @returns {(state: Object, props: Object) => Number} selector for sum of final price from addons with given groupingId
 * @example selectAddonTotalPriceByGroupingId(groupingId)(state, { petId });
 */
export const selectAddonTotalPriceByGroupingId = groupingId =>
  createSelector([selectAddonsByGroupingId(groupingId)], (addons = []) =>
    addons?.reduce((price, { pricing: { finalPrice = 0 } = {} } = {}) => price + finalPrice, 0),
  );
/**
 * Select original price for a given addon across engagements
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectAddonTotalOriginalPriceByGroupingId
 * @param {string} groupingId
 * @param {Object} state - redux state
 * @returns {(state: Object, props: Object) => Number} selector for sum of original price from addons with given groupingId
 * @example selectAddonTotalOriginalPriceByGroupingId(groupingId)(state, { petId });
 */
export const selectAddonTotalOriginalPriceByGroupingId = groupingId =>
  createSelector([selectAddonsByGroupingId(groupingId)], (addons = []) =>
    addons?.reduce((sum, { pricing: { originalPrice = 0 } = {} } = {}) => sum + originalPrice, 0),
  );

export const selectCheckInOutDateRangeForFrequencyByPet = createSelector(
  [getPetHotelEngagements, getStoreTimeZoneForHotelItinerary],
  (engagements, storeTimeZone) =>
    engagements
      ?.map(({ startDatetime } = {}) =>
        formatCalendarDateMoment(momentTz.tz(startDatetime, storeTimeZone)),
      )
      .sort((a, b) => moment(a).diff(moment(b))),
);

// Helper to add customFrequencyDate array's to food/med based on which engagement they are on
export const formatFoodOrMedFromEngagements = ({ petEngagements, isFood = true, timeZone }) => {
  const key = isFood ? "feedings" : "medications";
  const objs = {};

  petEngagements.forEach(({ metadata = {} } = {}) => {
    const array = metadata[key] ?? [];
    array.forEach(obj => {
      if (obj?.isDeleted) return;

      const date = formatCalendarDateMoment(obj?.startDatetime, timeZone);
      const groupingId = obj?.groupingId;

      if (objs[groupingId]) {
        // If groupingId is already in objs, then update the dates array for that obj
        objs[groupingId].customFrequencyDates.push(date);
      } else {
        // Else, add the obj with a new dates array
        objs[groupingId] = { ...obj, customFrequencyDates: [date] };
      }
    });
  });

  return Object.values(objs);
};

/**
 *  Selector to get all foods for a given pet on the current itinerary
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name selectItineraryPetFoodsByPetId
 *  @param {Object} state - redux state
 *  @param {Object} props
 *  @param {string} props.petId
 *  @returns {Object} engagement
 *  @example
 *
 *  selectItineraryPetFoodsByPetId(state, { petId })
 */
export const selectItineraryPetFoodsByPetId = createSelector(
  [getPetHotelEngagements, getStoreTimeZoneForHotelItinerary],
  (petEngagements = [], timeZone) => formatFoodOrMedFromEngagements({ petEngagements, timeZone }),
);

/**
 *  Selector to get all meds for a given pet on the current itinerary
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name selectItineraryPetMedsByPetId
 *  @param {Object} state - redux state
 *  @param {Object} props
 *  @param {string} props.petId
 *  @returns {Object} engagement
 *  @example
 *
 *  selectItineraryPetMedsByPetId(state, { petId })
 */
export const selectItineraryPetMedsByPetId = createSelector(
  [getPetHotelEngagements, getStoreTimeZoneForHotelItinerary],
  (petEngagements = [], timeZone) =>
    formatFoodOrMedFromEngagements({ petEngagements, isFood: false, timeZone }),
);

/**
 *  Selector to get the petService object off of the current engagement
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name selectCurrentHotelPetService
 *  @param {Object} state - redux state
 *  @param {Object} props
 *  @param {string} props.petId - param to specify pet
 *  @returns {Object} the petService object from and engagement
 *  @example
 *
 *  selectCurrentHotelPetService(state, { petId })
 */
export const selectCurrentHotelPetService = createSelector(
  [selectCurrentHotelEngagement],
  petEngagement => petEngagement?.petService ?? {},
);

/**
 *  Selector to get the last endDatetime from the Hotel Engagements
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name selectLastHotelEngagementDate
 *  @param {Object } state - redux state
 *  @return {moment.Moment} The latest end datetime as a Moment.js object
 *  @example
 *
 *  const lastHotelEngagementDate = selectLastHotelEngagementDate(state);
 */
export const selectLastHotelEngagementDate = createSelector([getHotelEngagements], engagements => {
  return engagements ? getLatestEndDatetime(engagements, "endDatetime") : "";
});

/**
 *  Selector to get a list of PetId and engagement types that are associated with a list of Engagement ids
 *  NOTE: the dictionary returned will have a key for engagementType will be set as "serviceType" as required by the Get hotel booking eligibility by pet Ids api.
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name selectAllPetIdAndEngagementTypesFromEngagementId
 *  @param {Object } state - redux state
 *  @return {Array} List of objects containing PetId and PetService names
 *  @example
 *
 *  const petsAndServiceTypes = selectAllPetIdAndEngagementTypesFromEngagementId(state);
 */
export const selectAllPetIdAndEngagementTypesFromEngagementId = createSelector(
  [getHotelEngagements],
  engagements => {
    return engagements
      ? getAllPetIdAndEngagementTypesFromEngagementId(Object.keys(engagements))(engagements)
      : [];
  },
);

/**
 *  Selector to get engagement types for a list of given petIds
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name selectPetIdsAndEngagementTypesForEligibility
 *  @param {Object} state - redux state
 *  @param {Object} props
 *  @param {Object} props.petIds
 *  @return {Array} List of objects containing PetId and PetService names
 *  @example selectPetIdsAndEngagementTypesForEligibility(state, { petIds });
 */
export const selectPetIdsAndEngagementTypesForEligibility = createSelector(
  [selectAllPetIdAndEngagementTypesFromEngagementId, getProps],
  (petsAndServiceTypes, { petIds } = {}) =>
    petsAndServiceTypes?.filter(({ petId }) => petIds?.includes(petId)) ?? [],
);

/**
 * Selector to Determines if a single pet's engagement (appointment) status is checked in.
 *
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectIsPetStatusCheckedIn
 * @param {Object} state - Redux state
 * @param {Number } petId - Id that represents a pet
 * @return {boolean}
 *
 * @example
 * const isCheckedIn = isPetStatusCheckedIn(state, {petId})
 */
export const selectIsPetStatusCheckedIn = createSelector(
  [getFirstHotelEngagementStatusByPet],
  appointmentStatus => {
    const isStatusCheckedIn = appointmentStatus === APPOINTMENT_STATUS.CHECKED_IN;

    return isStatusCheckedIn;
  },
);

/**
 * Selector to determine if all pet's engagement (appointment) status are checked in
 * Note: canceled pets are filtered out & treated as if they aren't on the itinerary
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectIsAllPetsStatusCheckedIn
 * @param {Object} state - Redux state
 * @return {boolean}
 *
 * @example
 * const isCheckedIn = selectIsAllPetsStatusCheckedIn(state)
 */
export const selectIsAllPetsStatusCheckedIn = createSelector(
  [getHotelEngagementsArray],
  listOfEngagements => {
    const statusList = listOfEngagements
      ?.filter(({ status }) => status !== APPOINTMENT_STATUS.CANCELED)
      ?.map(engagement => engagement.status === APPOINTMENT_STATUS.CHECKED_IN);

    return statusList.every(status => status === true);
  },
);

/**
 * Selector to get each pets engagement status
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectHotelPetStatuses
 * @param {Object} state - Redux state
 * @return {Object} obj where keys are petId's and values are statuses
 * @example selectHotelPetStatuses(state)
 */
export const selectHotelPetStatuses = createSelector([getHotelEngagementsArray], engagements => {
  const engObj = normalizeArrayByProperty(engagements, "petId");
  return Object.fromEntries(
    Object.entries(engObj)?.map(([petId, { status } = {}]) => [petId, status]),
  );
});

/**
 * Selector to get petIds of pets that can be checked in
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectHotelPetsToCheckIn
 * @param {Object} state - Redux state
 * @return {Number[]}
 * @example selectHotelPetsToCheckIn(state)
 */
export const selectHotelPetsToCheckIn = createSelector([selectHotelPetStatuses], petStatuses => {
  return Object.entries(petStatuses)
    ?.filter(
      ([petId, status]) =>
        status === APPOINTMENT_STATUS.BOOKED || status === APPOINTMENT_STATUS.CONFIRMED,
    )
    ?.map(([petId]) => Number(petId));
});

/**
 * Selector to get petIds of pets that can be checked out
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectHotelPetsToCheckOut
 * @param {Object} state - Redux state
 * @return {Number[]}
 * @example selectHotelPetsToCheckOut(state)
 */
export const selectHotelPetsToCheckOut = createSelector([selectHotelPetStatuses], petStatuses => {
  return Object.entries(petStatuses)
    ?.filter(([petId, status]) => status === APPOINTMENT_STATUS.CHECKED_IN)
    ?.map(([petId]) => Number(petId));
});

/**
 * Selector to get petIds of pets that aren't canceled
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectHotelPetsNotCanceled
 * @param {Object} state - Redux state
 * @return {Number[]}
 * @example selectHotelPetsNotCanceled(state)
 */
export const selectHotelPetsNotCanceled = createSelector([selectHotelPetStatuses], petStatuses =>
  Object.entries(petStatuses)
    ?.filter(([petId, status]) => status !== APPOINTMENT_STATUS.CANCELED)
    ?.map(([petId]) => Number(petId)),
);

/**
 * Selector to determine if all pet's have been assigned to a room or are marked 'unassigned'
 *
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectAllPetsHaveRoomAssigned
 * @param {Object} state - Redux state
 * @param {Object} props
 * @param {Number[]} props.petIds - optional param to specify which pets to check for rooms
 * @return {boolean}
 *
 * @example
 * const allPetsHaveRooms = selectAllPetsHaveRoomAssigned(state);
 */
export const selectAllPetsHaveRoomAssigned = createSelector(
  [selectHotelEngagementsByPetIds],
  listOfEngagements =>
    listOfEngagements.every(({ metadata = {} }) => {
      const room = metadata?.room || {};
      return room.roomNumber || room.isUnassigned;
    }),
);

/**
 * Selector to determine if all pet's reservations are done (checked out or canceled).
 *
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectIsAllPetsStatusCheckedOut
 * @param {Object} state - Redux state
 * @return {boolean}
 * @example selectIsAllPetsStatusCheckedOut(state)
 */
export const selectIsAllPetsStatusCheckedOut = createSelector(
  [getHotelEngagementsArray],
  engagements =>
    engagements?.length &&
    engagements.every(({ status } = {}) => checkIsReservationDone({ status })),
);

/**
 * Selector to determine if given pets are the last left to check out
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectIsLastToCheckOut
 * @param {Array} petIds
 * @return {Function} selector
 * @example selectIsLastToCheckOut(petIds)(state)
 */
export const selectIsLastToCheckOut = petIds =>
  createSelector([selectHotelPetStatuses], petsStatus => {
    if (!petIds?.length) return false;
    const petIdStrs = petIds.map(id => `${id}`);

    // If any pet doesn't have a status
    if (petIdStrs.some(petId => !petsStatus[petId])) return false;

    // If only 1 pet on itinerary
    if (Object.keys(petsStatus).length === 1) return true;

    // If all other pets are checked out, return true
    const otherPets = Object.keys(petsStatus).filter(petId => !petIdStrs.includes(petId));
    return otherPets.every(petId => checkIsReservationDone({ status: petsStatus[petId] }));
  });

/**
 * Selector to get room sharing pets with engagements by hostPetId
 *
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectPetsWithEngagementsByHostPetId
 * @param { String } hostPetId - Host PetId to filter shared pets in a room
 * @param { String } petId - PetId to filter shared pets in a room
 * @param { Object } state - Redux state
 * @return { Array } - Pets with engagements
 *
 * @example
 * const roomSharingPetsWithEngagements = selectPetsWithEngagementsByHostPetId(hostPetId)(state)
 */
export const selectPetsWithEngagementsByHostPetId = ({ hostPetId, petId }) =>
  createSelector([getHotelEngagementsArray], listOfEngagements => {
    if (!listOfEngagements.length) return [];

    const roomSharingPetEngagements = listOfEngagements?.filter(
      engagement =>
        !checkIsReservationDone(engagement) &&
        engagement.petId !== petId &&
        (engagement.hostPetId === hostPetId || engagement.hostPetId === petId),
    );

    if (!roomSharingPetEngagements.length) return [];

    const groupByPet = groupBy(roomSharingPetEngagements, "petId");
    const roomSharingPets = Object.keys(groupByPet).map(roomSharingPetId => {
      return {
        petId: +roomSharingPetId,
        engagements: groupByPet[roomSharingPetId],
      };
    });

    return roomSharingPets;
  });

/**
 * Selector to determine if the given pet is a host for any other pets on the itinerary
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectIsHostPet
 * @param {String} petId
 * @return {Object => Boolean}
 * @example const isHostPet = selectIsHostPet(petId)(state)
 */
export const selectIsHostPet = petId =>
  createSelector(
    [selectPetsWithEngagementsByHostPetId({ hostPetId: petId, petId })],
    roomSharingPets => {
      return !isEmpty(roomSharingPets);
    },
  );

/**
 * Selector to determine if the given pet is a host and if their service is being updated
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectIsUpdatingHostService
 * @param {Object} obj
 * @param {Number} obj.selectedService - petServiceId of selected service
 * @param {String} petId
 * @return {Object => Boolean}
 * @example selectIsUpdatingHostService({ selectedService, petId })(state)
 */
export const selectIsUpdatingHostService = ({ selectedService, petId }) =>
  createSelector(
    [selectIsHostPet(petId), selectHotelPetServiceById({ selectedService, petId })],
    (isHostPet, pendingService) => {
      return isHostPet && !!pendingService;
    },
  );

/**
 * Selector to get all child addons of a geven enhanced addon
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectEnhancedAddonChildrenFromEngagements
 * @param { String } addonId - product number of enhanced addon
 * @param { Object } state - Redux state
 * @param { Object } props
 * @param { String } props.petId
 * @return { { [productId: string]: Object } } - addons grouped by productId
 * @example selectEnhancedAddonChildrenFromEngagements(addonId)(state, { petId })
 */
export const selectEnhancedAddonChildrenFromEngagements = addonId =>
  createSelector(
    [selectAddonByPetAndAddonId(addonId), selectAllAddonsByPet],
    (existingAddon, addons) => {
      if (!existingAddon?.isEnhancedAddOn) return {};

      // Note: addOnProductNumber is not unique, an addon is a child of an enhanced addon
      // if its product number is in the included object AND if it is an auto applied addon
      const includedProducts = existingAddon?.includes?.products;
      const children = includedProducts?.map(({ productNumber }) => [
        productNumber,
        addons.find(
          ({ isAutoApplyAddOn, addOnProductNumber }) =>
            isAutoApplyAddOn && addOnProductNumber.toString() === productNumber.toString(),
        ),
      ]);
      return Object.fromEntries(children);
    },
  );

/**
 * Selector to get notes for an addon or child addons if the given addon is enhanced
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectAddonNotesFromEngagements
 * @param { String } addonId - product number of enhanced addon
 * @param { Object } state - Redux state
 * @param { Object } props
 * @param { String } props.petId
 * @return { { [productId: string]: { label: string, notes: string, id: string} } } - object containing addon notes, label for the notes input field, and productId
 * @example selectAddonNotesFromEngagements(addonId)(state, { petId })
 */
export const selectAddonNotesFromEngagements = addonId =>
  createSelector(
    [
      selectIncludedDescriptorsNotes(addonId),
      selectAddonByPetAndAddonId(addonId),
      selectEnhancedAddonChildrenFromEngagements(addonId),
    ],
    (includedDescriptorNotes, addon, children) => {
      const isAppliedAndEnhanced = !isEmpty(addon) && addon.isEnhancedAddOn;
      const isNotAppliedAndEnhanced = isEmpty(addon) && includedDescriptorNotes.length;
      const isEnhanced = isAppliedAndEnhanced || isNotAppliedAndEnhanced;

      // Addon isn't enhanced
      if (!isEnhanced)
        return { [addonId]: { id: addonId, notes: addon?.comment ?? "", label: undefined } };

      // Addon isn't applied yet but is enhanced
      if (isNotAppliedAndEnhanced) return includedDescriptorNotes;

      // Addon is enhanced and applied
      const childNotes = {};
      const childArr = Object.entries(children) ?? [];
      childArr.forEach(([id, { addOnName, comment } = {}]) => {
        childNotes[id] = { label: addOnName, notes: comment, id };
      });

      return childNotes;
    },
  );

/**
 * Selector to get room config for get pet services API call
 *
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectHotelItineraryRoomConfig
 * @param { Object } state - Redux state
 * @return { {
 *  fromItinerary: { roomPetConfigurations: { petIdsOfPetsInRoom: string[] }[] },
 *  guestsAsHosts?: { roomPetConfigurations: { petIdsOfPetsInRoom: string[] }[] },
 *  guestPetList?: string } }
 *  - pet room configs formatted for get pet services API
 * @example selectHotelItineraryRoomConfig(state)
 */
export const selectHotelItineraryRoomConfig = createSelector(
  [getHotelEngagementsArray],
  engagements => {
    if (!engagements.length) return {};

    const petRooms = {};
    const guestPets = [];

    engagements.forEach(({ petId, hostPetId }) => {
      const prev = petRooms[hostPetId] ?? [];
      if (prev.indexOf(petId) < 0) petRooms[hostPetId] = [...prev, petId];
      if (guestPets.indexOf(petId) < 0 && petId !== hostPetId) guestPets.push(petId);
    });

    const roomConfigs = {
      fromItinerary: {
        roomPetConfigurations: Object.values(petRooms).map(petIdsOfPetsInRoom => ({
          petIdsOfPetsInRoom,
        })),
      },
    };

    if (guestPets.length) {
      roomConfigs.guestsAsHosts = {
        roomPetConfigurations: guestPets.map(petId => ({ petIdsOfPetsInRoom: [petId] })),
      };

      // guestPetList will be the pet used in the url -> /pets/${petList}/petservices
      roomConfigs.guestPetList = guestPets[0];
    }

    return roomConfigs;
  },
);

/**
 * Selector to get petService's for a given pet, with date, roomNumber, and engagementId from engagement
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectPetHotelEngagementServices
 * @param { Object } state - Redux state
 * @param { Object } props
 * @param { string } props.petId
 * @return {Object[]} - array of petServices
 * @example selectPetHotelEngagementServices(state, { petId })
 */
export const selectPetHotelEngagementServices = createSelector(
  [getPetHotelEngagements, getStoreTimeZoneForHotelItinerary],
  (petEngagements = [], storeTimeZone) =>
    petEngagements
      ?.sort((a, b) => moment.utc(a.startDatetime).diff(moment.utc(b.startDatetime)))
      .map(({ engagementId, startDatetime, petService = {}, metadata = {} }) => {
        const date = momentTz.tz(startDatetime, storeTimeZone);
        return {
          engagementId,
          name: petService?.petServiceName,
          date,
          formattedDate: formatDayNameMonthDay(startDatetime),
          price: petService?.pricing?.finalPrice,
          currencyCode: petService?.pricing?.currencyCode,
          roomNumber: metadata?.room?.roomNumber,
          roomTypeBucketId: metadata?.room?.roomTypeBucketId,
        };
      }),
);

/**
 * Selector to get whether a pet's check in day is today or in the past
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectIsCheckInTodayOrPast
 * @param {Object} state - Redux state
 * @param {Object} props
 * @param {string} props.petId
 * @param {string} props.today - optional param to specify "today"'s date (for testing)
 * @return {boolean}
 * @example selectIsCheckInTodayOrPast(state, { petId })
 */
export const selectIsCheckInTodayOrPast = createSelector(
  [getFirstHotelEngagementByPet, getStoreTimeZoneForHotelItinerary, (state, props) => props],
  ({ startDatetime } = {}, storeTimeZone, { today } = {}) => {
    const todayInStoreTz = momentTz.tz(moment(today), storeTimeZone);
    const startInStoreTz = momentTz.tz(startDatetime, storeTimeZone);

    return todayInStoreTz.isSameOrAfter(startInStoreTz, "day");
  },
);

/**
 * Selector to get whether a pet's check out day is today
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectIsCheckoutToday
 * @param {Object} state - Redux state
 * @param {Object} props
 * @param {string} props.petId
 * @param {string} props.today - optional param to specify "today"'s date (for testing)
 * @return {boolean}
 * @example selectIsCheckoutToday(state, { petId })
 */
export const selectIsCheckoutToday = createSelector(
  [getLastHotelEngagementByPet, getStoreTimeZoneForHotelItinerary, (__, props) => props],
  ({ endDatetime } = {}, storeTimeZone, { today } = {}) => {
    const todayInStoreTz = momentTz.tz(moment(today), storeTimeZone);
    const endDateInStoreTz = momentTz.tz(endDatetime, storeTimeZone);

    return todayInStoreTz.isSame(endDateInStoreTz, "day");
  },
);

/**
 * Selector to get whether a pet's check out day is before a given date
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectIsCheckOutInPast
 * @param {Object} state - Redux state
 * @param {Object} props
 * @param {string} props.petId
 * @param {string} props.date - optional param to date used for comparison (defaults to today)
 * @return {boolean}
 * @example selectIsCheckOutInPast(state, { petId })
 */
export const selectIsCheckOutInPast = createSelector(
  [getLastHotelEngagementByPet, getStoreTimeZoneForHotelItinerary, (__, props) => props],
  ({ endDatetime } = {}, storeTimeZone, { date } = {}) => {
    const dateInStoreTz = momentTz.tz(date, storeTimeZone);
    const endDateInStoreTz = momentTz.tz(endDatetime, storeTimeZone);

    return endDateInStoreTz.isBefore(dateInStoreTz, "day");
  },
);

/**
 * Selector to get whether all engagement service ids match
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectAllPetServicesMatch
 * @param {Object} state - Redux state
 * @param {Object} props
 * @param {string} props.petId
 * @return {boolean}
 * @example selectAllPetServicesMatch(state, { petId })
 */
export const selectAllPetServicesMatch = createSelector([getPetHotelEngagements], engagementsList =>
  allEngagementPetServiceIdsMatch(engagementsList),
);

/**
 * Selector to get a list of all active pets on the current hotel itinerary
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectActiveHotelEngagementsPets
 * @param {Object} state - redux state
 * @returns {Array} array pet ids for active pets
 * @example selectActiveHotelEngagementsPets(state)
 */
export const selectActiveHotelEngagementsPets = createSelector(
  [getHotelEngagementsPets, getPets],
  (engagementPets, pets = {}) => {
    return engagementPets?.filter(petId => pets[petId]?.isActive);
  },
);

/**
 * Helper to check if a pet has already been added to a room
 * @function
 * @name selectRebookingPetRooms
 * @param {Object} rooms
 * @param {String} petId
 * @return {Boolean}
 */
export const checkIsPetInARoom = (rooms = {}, petId) => {
  return Object.values(rooms)?.some(room => room?.some(pet => pet === petId));
};

/**
 * Selector to get room selections for re booking from itinerary
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectRebookingPetRooms
 * @param {Object} state - Redux state
 * @return {Object} rooms object for hotel booking state
 * @example selectRebookingPetRooms(state)
 */
export const selectRebookingPetRooms = createSelector(
  [getHotelEngagementsArray, selectHotelBookingPetList],
  (listOfEngagements, rebookingPets) => {
    const rooms = {};

    listOfEngagements.map(({ petId, hostPetId } = {}) => {
      const room = rooms[hostPetId] ?? [];
      const isPetInARoom = checkIsPetInARoom(rooms, petId);
      const isPetBeingReBooked = rebookingPets?.some(pet => pet === petId);
      if (isPetBeingReBooked && !isPetInARoom) {
        rooms[hostPetId] = [...room, petId];
      }
    });

    return Object.fromEntries(Object.values(rooms).map((pets, index) => [index + 1, pets]));
  },
);

/**
 * Selector to get service selections for re booking from itinerary
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectRebookingPetServices
 * @param {Object} state - Redux state
 * @return {Object} object with petId's as keys and values containing petServicesId's
 * @example selectRebookingPetServices(state)
 */
export const selectRebookingPetServices = createSelector(
  [
    selectActiveHotelEngagementsPets,
    getHotelEngagementsArray,
    state => petId => getPendingAppointmentServicesByPet({ petId })(state),
  ],
  (rebookingPets, sortedEngagements, getServicesForPet) => {
    return rebookingPets.reduce((petsWithServices, petId) => {
      const engagement = sortedEngagements?.find(eng => eng?.petId === petId);
      const petServiceId = engagement?.petService?.petServiceId;
      const petServices = getServicesForPet(petId);
      const service = petServices?.find(service => service?.petServiceId === petServiceId);

      if (service) return { ...petsWithServices, [petId]: service };
      return petsWithServices;
    }, {});
  },
);

/**
 * Helper to check if an addon with a given id exists
 * @function
 * @name checkIfAddonAvailable
 * @param {Object} availableAddons - addons from get addons call
 * @param {Object} productNum - addOnProductNumber from an addon on an itinerary
 * @return {Boolean}
 * @example checkIfAddonAvailable(availableAddons, productNum)
 */
export const checkIfAddonAvailable = (availableAddons, productNum) =>
  availableAddons?.some(({ addOnId }) => addOnId === productNum);

/**
 * Selector to get array addons that are available for rebooking
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectRebookingAvailableAddons
 * @param {Object} state - Redux state
 * @param {Object} props
 * @param {String} props.petId
 * @return {Array} array of strings
 * @example selectRebookingAvailableAddons(state, { petId })
 */
export const selectRebookingAvailableAddons = createSelector(
  [selectAppliedAddonsFromEngagementsByPet, selectHotelAddonsListAddonsByPet],
  (itineraryAddons, availableAddons) => {
    return itineraryAddons?.filter(
      ({ addOnProductNumber, isAutoApplyAddOn }) =>
        !isAutoApplyAddOn && checkIfAddonAvailable(availableAddons, addOnProductNumber),
    );
  },
);

/**
 * Selector to get names of addons that aren't available for rebooking
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectRebookingNotAvailableAddonsByPet
 * @param {Object} state - Redux state
 * @param {Object} props
 * @param {String} props.petId
 * @return {Array} array of strings
 * @example selectRebookingNotAvailableAddonsByPet(state, { petId })
 */
export const selectRebookingNotAvailableAddonsByPet = createSelector(
  [selectAppliedAddonsFromEngagementsByPet, selectHotelAddonsListAddonsByPet],
  (itineraryAddons, availableAddons) => {
    return itineraryAddons?.filter(
      ({ addOnProductNumber, isAutoApplyAddOn }) =>
        !isAutoApplyAddOn && !checkIfAddonAvailable(availableAddons, addOnProductNumber),
    );
  },
);

/**
 * Selector to get names of addons that aren't available for rebooking for all pets
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectRebookingNotAvailableAddons
 * @param {Object} state - Redux state
 * @return {Array} array of strings
 * @example selectRebookingNotAvailableAddons(state)
 */
export const selectRebookingNotAvailableAddons = createSelector(
  [
    selectHotelBookingPetList,
    state => petId => selectRebookingNotAvailableAddonsByPet(state, { petId }),
  ],
  (pets, getNotAvailableAddonsByPet) => {
    const notAvailableAddons = pets?.reduce((notAvailAddons, petId) => {
      const petNotAvailableAddons = getNotAvailableAddonsByPet(petId);
      return [...notAvailAddons, ...petNotAvailableAddons];
    }, []);

    return uniqBy(notAvailableAddons, "addOnProductNumber");
  },
);

/**
 * Selector to get pet addons for rebooking from itinerary
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectRebookingAddons
 * @param {Object} state - Redux state
 * @param {Object} props
 * @param {String} props.petId
 * @param {String} props.isRebooking
 * @return {Array} array of addons formatted for cart API call
 * @example selectRebookingAddons(state, { petId, isRebooking })
 */
export const selectRebookingAddons = createSelector(
  [selectRebookingAvailableAddons, (state, props) => props],
  (addons = [], { isRebooking } = {}) => {
    if (!isRebooking) return [];

    return addons?.map(addon => {
      return {
        productId: addon?.addOnProductNumber,
        productName: addon?.addOnName,
        isPrimaryService: false,
        frequency: addon?.frequency,
        notes: addon?.comment,
        singleDayStayQuantity: addon?.quantity,
      };
    });
  },
);
