import React from "react";
import { connect } from "react-redux";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { selectCartDetailsByPet } from "@/core/selectors/cartSelectors";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { Heading, Hr } from "@petsmart-ui/sparky";
import { color } from "@/web/common/styles/theme";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { getPetServiceItem } from "@/core/selectors/entitiesSelector";
import ReduceMaxLimitContainer from "@/web/common/ReduceMaxLimit/ReduceMaxLimitContainer";
import { isCartDetailsEditable } from "@/core/selectors/checkInOutSelector";
import PaidVoidTransactionContainer from "@/web/petCheckInOut/appointmentDetails/paidVoidTransaction/PaidVoidTransactionContainer";
import InvoiceGeneratedContainer from "@/web/petCheckInOut/appointmentDetails/invoiceGenerated/InvoiceGeneratedContainer";
import quickQuoteConstants from "@/core/constants/quickQuoteConstants";
import { getSelectedPet } from "@/core/selectors/bookingUISelectors";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import { HotelCheckInCartReservationNotes } from "@/web/bookingCartReservationNotes/BookingCartReservationNotesComponent";
import { HotelCheckInOutInvoiceGeneratedTotalPrice } from "@/web/invoiceGeneratedTotalPrice/InvoiceGeneratedTotalPriceComponent";
import { HotelCheckInOutInvoiceGeneratedCheckbox } from "@/web/invoiceGeneratedTotalPrice/InvoiceGeneratedCheckBox";
import { SalonAppointmentNotes } from "../notes/appointmentNotes/AppointmentNotesComponent";
import { StoreNameNumberAddress } from "../storeNameNumberAddress/StoreNameNumberAddress";
import HotelPetCardContainer from "../_components/checkInOutCart/appointmentDetails/PetCardComponent";
import { HotelCheckInOutAppointmentStatusLabel } from "../_components/checkInOutCart/AppointmentStatusLabel";
import {
  HotelCheckInOutUndoMultiStatus,
  HotelCheckInOutUndoSingleStatus,
} from "../_components/checkInOutCart/UndoButton";
import { HotelCheckInOutDateTimeWrapper } from "../hotelCheckInOutDateTimeCart/hotelCheckInOutTimeDateCartWrapperComponent";
import { HotelCheckInOutPetNeedsReviewContainer } from "../hotelCheckInOutPetNeedsReview/hotelCheckInOutPetNeedsReview";
import CheckInOutAppointmentSummary from "../_components/checkInOutAppointmentSummary/CheckInOutApppointmentSummary";
import { AppointmentSummaryPriceLayoutCheckInWrapper } from "../checkInOut/layoutComponentWrappers/appointmentSummaryPrice/appointmentSummarylPriceLayoutWraperView";
import { CheckInOutFoods } from "../_components/checkInOutFeeding/CheckInOutFoods";
import { CheckInOutMeds } from "../_components/checkInOutMedications/CheckInOutMeds";
import { HotelCheckInOutCartAddonsSection } from "../hotelCartAddons/HotelCartAddonsList";
import { HotelCancelSingleAppointment } from "../hotelCancelAppt/hotelCancelApptButton";
import { HotelCheckInOutRebookLoginMsg } from "../appointmentRebook/AppointmentRebookLoginMsg";
import { HotelCheckInOutRebookButton } from "../appointmentRebook/AppointmentRebookButton";
import { CheckInOutPaidVoidTransaction } from "../_components/invoice/PaidVoidTransaction";

const EmptyComp = () => <></>;

/**
 * React view component that enforces the layout for pet appointment details in "cart" columns
 * @function
 * @name AppointmentColumn
 * @param {Object} props
 * @param {string} props.componentId
 * @param {Boolean} props.isHidden
 * @param {string?} props.border
 * @param {string?} props.appointmentSummaryHeading
 * @returns {JSX.Element|null}
 */
export const PetAppointmentDetails = props => {
  const { componentId, isHidden, border, appointmentSummaryHeading, petId } = props;

  if (isHidden) return null;

  // Dependency injected components
  const PetCard = props?.petCardComp || EmptyComp;
  const Cancel = props?.cancelComp || EmptyComp;
  const BoardedGuest = props?.boardedGuestComp || EmptyComp;
  const ModifyAppointment = props?.modifyAppointmentComp || EmptyComp;
  const History = props?.historyComp || EmptyComp;
  const Status = props?.statusComp || EmptyComp;
  const Undo = props?.undoComp || EmptyComp;
  const StoreAddress = props?.storeAddressComp || EmptyComp;
  const AppointmentDateTimes = props?.appointmentDateTimesComp || EmptyComp;
  const ReviewReservation = props?.reviewReservationComp || EmptyComp;
  const Associate = props?.associateComp || EmptyComp;
  const ApplySpecials = props?.applySpecialsComp || EmptyComp;
  const Room = props?.roomComp || EmptyComp;
  const PrimaryService = props?.primaryServiceComp || EmptyComp;
  const Foods = props?.foodsComp || EmptyComp;
  const Meds = props?.medsComp || EmptyComp;
  const EnhancedServices = props?.enhancedServicesComp || EmptyComp;
  const Addons = props?.addonsComp || EmptyComp;
  const Overbooking = props?.overbookingComp || EmptyComp;
  const CancelReservation = props?.cancelReservationComp || EmptyComp;
  const Rebook = props?.rebookComp || EmptyComp;
  const RebookMsg = props?.rebookMsgComp || EmptyComp;
  const TotalPrice = props?.totalPriceComp || EmptyComp;
  const InvoicePaid = props?.invoicePaidComp || EmptyComp;
  const InvoiceGenerated = props?.invoiceGeneratedComp || EmptyComp;
  const Notes = props?.notesComp || EmptyComp;

  const hasStatusOrUndo = !!props?.statusComp || !!props?.undoComp;
  const hasBoardedGuestOrModify = !!props?.boardedGuestComp || !!props?.modifyAppointmentComp;
  const hasAssociate = !!props?.associateComp;
  const hasSpecialsBtnOrHeadingText = !!props?.applySpecialsComp || !!appointmentSummaryHeading;
  const hasNotes = !!props?.notesComp;
  const hasCancelRes = !!props?.cancelReservationComp;
  const hasRebook = !!props?.rebookComp;
  const hasPricingOrInvoice =
    !!props?.totalPriceComp || !!props?.invoicePaidComp || !!props?.invoiceGeneratedComp;

  return (
    <LayoutBox id={componentId} style={{ border }} padding={border ? "scale-G1" : "scale-0"}>
      <LayoutStack space="scale-G1">
        <LayoutBox padding="scale-0">
          <LayoutCluster style={{ justifyContent: "space-between" }}>
            <PetCard petId={petId} />
            <Cancel petId={petId} />
          </LayoutCluster>
        </LayoutBox>

        <History petId={petId} />

        {hasStatusOrUndo && (
          <LayoutBox padding="scale-0">
            <LayoutCluster style={{ justifyContent: "space-between" }}>
              <Status petId={petId} />
              <Undo petId={petId} />
            </LayoutCluster>
          </LayoutBox>
        )}

        <StoreAddress />
        <AppointmentDateTimes petId={petId} />
        <ReviewReservation petId={petId} />

        {hasBoardedGuestOrModify && (
          <LayoutBox padding="scale-0">
            <LayoutCluster style={{ justifyContent: "space-between" }}>
              <BoardedGuest petId={petId} />
              <ModifyAppointment petId={petId} />
            </LayoutCluster>
          </LayoutBox>
        )}

        <Hr style={{ marginLeft: 0, marginRight: 0 }} />

        <Associate petId={petId} />
        {hasAssociate && <Hr style={{ marginLeft: 0, marginRight: 0 }} />}

        {hasSpecialsBtnOrHeadingText && (
          <LayoutBox padding="scale-0">
            <LayoutCluster style={{ justifyContent: "space-between" }}>
              {appointmentSummaryHeading && (
                <Heading tagName="h4" size="body-lg-bold">
                  {appointmentSummaryHeading}
                </Heading>
              )}
              <ApplySpecials petId={petId} />
            </LayoutCluster>
          </LayoutBox>
        )}

        <Room petId={petId} />
        <PrimaryService petId={petId} />
        <Foods petId={petId} />
        <Meds petId={petId} />
        <EnhancedServices petId={petId} />
        <Addons petId={petId} />
        <Overbooking petId={petId} />

        {hasNotes && <Hr style={{ marginLeft: 0, marginRight: 0 }} />}
        <Notes petId={petId} />

        {hasCancelRes && (
          <LayoutCluster style={{ justifyContent: "flex-end" }}>
            <CancelReservation petId={petId} />
          </LayoutCluster>
        )}

        {hasRebook && (
          <LayoutCluster style={{ justifyContent: "flex-end" }}>
            <Rebook petId={petId} />
          </LayoutCluster>
        )}

        <RebookMsg petId={petId} />

        {hasPricingOrInvoice && (
          <Hr style={{ marginLeft: 0, marginRight: 0, border: `1px dashed ${color.borderGrey}` }} />
        )}
        {hasPricingOrInvoice && (
          <LayoutBox padding="scale-0">
            <LayoutStack>
              <TotalPrice petId={petId} />
              <InvoicePaid petId={petId} />
              <InvoiceGenerated petId={petId} />
            </LayoutStack>
          </LayoutBox>
        )}
      </LayoutStack>
    </LayoutBox>
  );
};

/**
 * Redux Connect function for the salon check in, check out, future appointment, & past appointment page pet appointment details
 * @function
 * @name SalonPetAppointmentDetails
 * @param {Object} props
 * @param {Number|String} props.petId
 * @returns {JSX.Element|null}
 * @example <SalonPetAppointmentDetails />
 */
export const SalonPetAppointmentDetails = withRouteProps(
  connect((state, { petId, router }) => {
    const appointment = selectCartDetailsByPet(state, { petId });
    if (!appointment) return { isHidden: true };

    const customerKey = getCurrentCustomerKey(state);
    const petServiceItemId = appointment?.petServiceItemId;
    const petServiceItem = getPetServiceItem(state, { petServiceItemId }) || false;
    const isEditable = isCartDetailsEditable(state, { petServiceItemId });
    const itineraryId = router?.params?.itineraryId;
    const showNotes = !appointment.isStandalone;

    return {
      componentId: "SalonPetAppointmentDetails",
      appointmentSummaryHeading: "Appointment summary",
      petId,
      petCardComp: () => <LayoutBox borderWidth="scale-S10">PET CARD</LayoutBox>,
      cancelComp: () => <LayoutBox borderWidth="scale-S10">CANCEL</LayoutBox>,
      boardedGuestComp: () => <LayoutBox borderWidth="scale-S10">BOARDED GUEST</LayoutBox>,
      modifyAppointmentComp: () => <LayoutBox borderWidth="scale-S10">MODIFY OR CANCEL</LayoutBox>,
      historyComp: () => <LayoutBox borderWidth="scale-S10">APT# & HISTORY</LayoutBox>,
      statusComp: () => <LayoutBox borderWidth="scale-S10">STATUS</LayoutBox>,
      undoComp: () => <LayoutBox borderWidth="scale-S10">UNDO</LayoutBox>,
      storeAddressComp: () => <StoreNameNumberAddress heading="" />,
      appointmentDateTimesComp: () => <LayoutBox borderWidth="scale-S10">DATES & TIMES</LayoutBox>,
      associateComp: () => <LayoutBox borderWidth="scale-S10">ASSOCIATE</LayoutBox>,
      applySpecialsComp: () => <LayoutBox borderWidth="scale-S10">APPLY SPECIALS</LayoutBox>,
      primaryServiceComp: () => <LayoutBox borderWidth="scale-S10">SERVICE</LayoutBox>,
      enhancedServicesComp: () => <LayoutBox borderWidth="scale-S10">ENHANCED SERVICES</LayoutBox>,
      addonsComp: () => <LayoutBox borderWidth="scale-S10">ADDONS</LayoutBox>,
      overbookingComp:
        !appointment.isStandalone &&
        (() => (
          <ReduceMaxLimitContainer
            disabled={!isEditable}
            customerKey={customerKey}
            petId={petId}
            currentAppointment={petServiceItem}
          />
        )),
      totalPriceComp: () => <LayoutBox borderWidth="scale-S10">PRICE</LayoutBox>,
      invoicePaidComp: PaidVoidTransactionContainer,
      invoiceGeneratedComp: InvoiceGeneratedContainer,
      notesComp:
        showNotes &&
        (() => (
          <SalonAppointmentNotes
            label="Appointment Notes"
            petId={petId}
            customerId={customerKey}
            itineraryId={itineraryId || (petServiceItem && petServiceItem.itinerary)}
            petServiceItemId={petServiceItem && petServiceItem.petServiceItemId}
          />
        )),
    };
  })(PetAppointmentDetails),
);

/**
 * Redux Connect function for the salon booking pet appointment details
 * @memberOf Views.Booking
 * @function
 * @name SalonBookingPetAppointmentDetails
 * @param {Object} props
 * @param {Number|String} props.petId
 * @returns {JSX.Element|null}
 * @example <SalonBookingPetAppointmentDetails />
 */
export const SalonBookingPetAppointmentDetails = withRouteProps(
  connect((state, { petId, router: { params } }) => {
    const appointment = selectCartDetailsByPet(state, { petId });
    if (!appointment) return { isHidden: true };

    const selectedPet = getSelectedPet(state);
    const isSelectedPet = petId?.toString() === selectedPet?.toString();
    const customerKey = getCurrentCustomerKey(state);
    const petServiceItemId = appointment?.petServiceItemId;
    const petServiceItem = getPetServiceItem(state, { petServiceItemId }) || false;
    const isEditable = isCartDetailsEditable(state, { petServiceItemId });

    const itineraryId = params?.itineraryId;
    const isStandalone = appointment?.isStandalone;
    const associateId = appointment?.timeSlot?.associateId;

    return {
      componentId: "SalonBookingPetAppointmentDetails",
      border: `1px solid ${isSelectedPet ? color.kalEl : color.borderGrey}`,
      appointmentSummaryHeading: "Appointment summary",
      petId,
      petCardComp: () => <LayoutBox borderWidth="scale-S10">{petId} PET CARD</LayoutBox>,
      cancelComp: () => <LayoutBox borderWidth="scale-S10">CANCEL</LayoutBox>,
      boardedGuestComp: () => <LayoutBox borderWidth="scale-S10">BOARDED GUEST</LayoutBox>,
      associateComp:
        associateId && (() => <LayoutBox borderWidth="scale-S10">ASSOCIATE</LayoutBox>),
      applySpecialsComp:
        !isStandalone && (() => <LayoutBox borderWidth="scale-S10">APPLY SPECIALS</LayoutBox>),
      primaryServiceComp:
        !isStandalone && (() => <LayoutBox borderWidth="scale-S10">SERVICE</LayoutBox>),
      enhancedServicesComp:
        !isStandalone && (() => <LayoutBox borderWidth="scale-S10">ENHANCED SERVICES</LayoutBox>),
      addonsComp: () => <LayoutBox borderWidth="scale-S10">ADDONS</LayoutBox>,
      overbookingComp:
        !isStandalone &&
        (() => (
          <ReduceMaxLimitContainer
            disabled={!isEditable}
            customerKey={customerKey}
            petId={petId}
            currentAppointment={petServiceItem}
          />
        )),
      totalPriceComp: () => <LayoutBox borderWidth="scale-S10">PRICE</LayoutBox>,
      invoiceGeneratedComp: InvoiceGeneratedContainer,
      notesComp:
        !isStandalone &&
        (() => (
          <SalonAppointmentNotes
            label="Appointment Notes"
            petId={petId}
            customerId={customerKey}
            itineraryId={itineraryId || (petServiceItem && petServiceItem.itinerary)}
            petServiceItemId={petServiceItem && petServiceItem.petServiceItemId}
          />
        )),
    };
  })(PetAppointmentDetails),
);

/**
 * Redux Connect function for the salon quick quote pet appointment details
 * @memberOf Views.QuickQuote
 * @function
 * @name SalonQuickQuotePetAppointmentDetails
 * @returns {JSX.Element|null}
 * @example <SalonQuickQuotePetAppointmentDetails />
 */
export const SalonQuickQuotePetAppointmentDetails = connect(state => {
  const petId = quickQuoteConstants.QUOTE_PET;
  const appointment = selectCartDetailsByPet(state, { petId });
  if (!appointment) return { isHidden: true };

  const customerKey = getCurrentCustomerKey(state);
  const petServiceItemId = appointment?.petServiceItemId;
  const petServiceItem = getPetServiceItem(state, { petServiceItemId }) || false;

  return {
    componentId: "SalonPetAppointmentDetails",
    border: `1px solid ${color.kalEl}`,
    petId,
    petCardComp: () => <LayoutBox borderWidth="scale-S10">PET CARD</LayoutBox>,
    primaryServiceComp: () => <LayoutBox borderWidth="scale-S10">SERVICE</LayoutBox>,
    enhancedServicesComp: () => <LayoutBox borderWidth="scale-S10">ENHANCED SERVICES</LayoutBox>,
    addonsComp: () => <LayoutBox borderWidth="scale-S10">ADDONS</LayoutBox>,
    overbookingComp: () =>
      !appointment.isStandalone && (
        <ReduceMaxLimitContainer
          customerKey={customerKey}
          petId={petId}
          currentAppointment={petServiceItem}
        />
      ),
    totalPriceComp: () => <LayoutBox borderWidth="scale-S10">PRICE</LayoutBox>,
    invoiceGeneratedComp: InvoiceGeneratedContainer,
  };
})(PetAppointmentDetails);

/**
 * Redux Connect function for the hotel check in, check out appointment page pet appointment details
 * @function
 * @name HotelPetAppointmentDetails
 * @param {Object} props
 * @returns {JSX.Element|null}
 * @example <HotelPetAppointmentDetails />
 */
export const HotelPetAppointmentDetails = connect(state => {
  const petId = getCurrentPet(state);

  return {
    componentId: "HotelPetAppointmentDetails",
    petId,
    petCardComp: HotelPetCardContainer,
    statusComp: HotelCheckInOutAppointmentStatusLabel,
    undoComp: () => (
      <LayoutCluster>
        <HotelCheckInOutUndoSingleStatus petId={petId} />
        <HotelCheckInOutUndoMultiStatus />
      </LayoutCluster>
    ),
    appointmentDateTimesComp: HotelCheckInOutDateTimeWrapper,
    reviewReservationComp: HotelCheckInOutPetNeedsReviewContainer,
    primaryServiceComp: AppointmentSummaryPriceLayoutCheckInWrapper,
    roomComp: CheckInOutAppointmentSummary,
    foodsComp: CheckInOutFoods,
    medsComp: CheckInOutMeds,
    addonsComp: HotelCheckInOutCartAddonsSection,
    notesComp: HotelCheckInCartReservationNotes,
    cancelReservationComp: HotelCancelSingleAppointment,
    rebookComp: HotelCheckInOutRebookButton,
    rebookMsgComp: HotelCheckInOutRebookLoginMsg,
    totalPriceComp: HotelCheckInOutInvoiceGeneratedTotalPrice,
    invoicePaidComp: CheckInOutPaidVoidTransaction,
    invoiceGeneratedComp: HotelCheckInOutInvoiceGeneratedCheckbox,
  };
})(PetAppointmentDetails);
