import { getState } from "@/core/selectors/commonSelector";
import { createSelector } from "reselect";

/**
 * Selector to get available ddc packages
 * @memberOf Selectors.ddcPackages
 * @function
 * @name selectDdcPackages
 * @param {Object} state
 * @returns {Array} Available DDC packages
 * @example selectDdcPackages(state)
 */
export const selectDdcPackages = createSelector([getState], state => {
  return state?.ddcPackages?.ddcPackages || [];
});

/**
 * Selector to select selected ddc packages
 * @memberOf Selectors.ddcPackages
 * @function
 * @name selectSelectedDdcPackages
 * @param {Object} state
 * @returns {object} Selected DDC packages
 * @example selectSelectedDdcPackages(state)
 */
export const selectSelectedDdcPackages = createSelector([getState], state => {
  return state?.ddcPackages?.selectedPackages || {};
});
