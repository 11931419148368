import { associateWebBFFAPI } from "./_axiosConfigAssociateWebBff";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * API to get available ddc packages for a customer and region
 * @memberOf APIs.ddcPackages
 * @param {string} - customerKey
 * @param {string} - region
 * @param {string} - storeNumber
 * @function getDdcPackagesByCustomer
 * @returns {*} AxiosPromise
 */
export function getDdcPackagesByCustomer({ customerKey, region = "US", storeNumber }) {
  return associateWebBFFAPI.get(
    `${endpointVersionString()}/hotel/stores/${storeNumber}/customers/${customerKey}/packages?region=${region}`,
  );
}
