// Action Types
import {
  GET_DDC_PACKAGES_SUCCESS,
  GET_DDC_PACKAGES_FAILURE,
  SET_SELECTED_DDC_PACKAGES,
} from "./ddcPackagesActions";

/**
 * @name ddcPackagesReducer
 * @memberof Reducer.ddcPackages
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
const ddcPackagesReducer = (
  state = { ddcPackages: [], error: null, selectedPackages: {} },
  action,
) => {
  switch (action.type) {
    case GET_DDC_PACKAGES_SUCCESS:
      return {
        ...state,
        ddcPackages: action.ddcPackages,
      };
    case GET_DDC_PACKAGES_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case SET_SELECTED_DDC_PACKAGES:
      return {
        ...state,
        selectedPackages: action.selectedPackages,
      };
    default:
      return state;
  }
};

export default ddcPackagesReducer;
